import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Menu, Dropdown } from "antd";
import styled from "styled-components";
import themeConfig from "src/configs/theme.config";
import { useIntl } from "react-intl";
import { DownOutlined } from "@ant-design/icons";
// Components
import WordLogo from "src/components/shared/logo/WordLogo";
import ContactDropdown from "src/components/layout/ContactDropdown";
//Asset
import { ReactComponent as ContactIcon } from "src/assets/images/icons/contact_us.svg";

const propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string,
        id: PropTypes.string,
        icon: PropTypes.object
    })),
    selectedKey: PropTypes.string,
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    onClick: PropTypes.func
};

const defaultProps = {
    navItems: [],
    selectedKey: "",
    visible: false,
    setVisible: () => { },
    onClick: () => { }
};


const renderSideNavItems = (intl, navItems, onClick) => (
    navItems.map(item => (
        <Menu.Item key={`nav-item-${item.id}`} onClick={() => onClick(item.path)}>
            <div className="nav-item">
                {item.icon}
                <span className="big-title">{intl.formatMessage({ id: item.id })}</span>
            </div>
            <div className="divider" />
        </Menu.Item>
    )));

const Sidebar = ({ navItems, visible, setVisible, selectedKey, onClick }) => {
    const intl = useIntl();
    return (
        <StyledDrawer
            closable={false}
            placement="left"
            onClose={() => setVisible(false)}
            visible={visible}
            className="sidebar">
            <div className="logo-wrapper">
                <WordLogo />
            </div>
            <div className="menu-content">
                <Menu mode="inline" selectedKeys={[selectedKey]}>
                    {renderSideNavItems(intl, navItems, onClick)}
                </Menu>
                <Dropdown
                    trigger={['click']}
                    overlay={<ContactDropdown sidebar={true} />}>
                    <div className="nav-item contact-item">
                        <ContactIcon />
                        <span >{intl.formatMessage({ id: "nav.header.contact" })}</span>
                        <DownOutlined />
                    </div>
                </Dropdown>
            </div>
        </StyledDrawer>
    );
};

const StyledDrawer = styled(Drawer)`
    &.sidebar{
        .logo-wrapper{
            height: 48px;
            display: flex;
            align-items: center;
            padding: 0 32px;
        }
        .menu-content{
            background-color: ${themeConfig.color.background};
            height: calc(100% - 48px);
        }
        .nav-item{
            padding: 8px 0 12px 12px;
            color: ${themeConfig.color.primary_400};
            display: flex;
            align-items: center;
            span{
                margin-left: 10px;
                font-weight: 300;
            }
        }
        .contact-item{
            margin: 0 23px;
            height: 60px;
        }
        .ant-drawer-body{
            padding: 0
        }
        .divider{
            height: 1px;
            background: rgba(225, 225, 225, 1)
        }
        .ant-menu-item{
            height: fit-content;
        }
        .ant-menu{
            background-color: ${themeConfig.color.background};
        }
    }
`;
Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;
export default Sidebar;