import dayjs from "dayjs";

// Configs
import { apiInstance } from "src/configs/axios.config";
import environmentConfig from "src/configs/environment.config";

// Constants
import { LOCALE, LS_LOCALE, MIN_BUDDHIST_YEAR, MAX_BUDDHIST_YEAR } from "src/constants";

// Helpers
import * as LocaleHelper from 'src/helpers/locale.helper';
import * as AxiosHelper from "src/helpers/axios.helper";

export const setLocale = (locale) => {
    localStorage.setItem(LS_LOCALE, locale);
    AxiosHelper.setInstanceLocale(apiInstance, locale);
    dayjs.locale(locale);
};

export const getDefaultLocale = () => {
    const locale = localStorage.getItem(LS_LOCALE);

    const key = Object.keys(LOCALE).find(key => LOCALE[key].value === locale);
    const localeObj = key ? LOCALE[key] : environmentConfig.LOCALE;

    LocaleHelper.setLocale(localeObj.value);

    return localeObj;
};

export const getLocaleByKey = (value) => {
    const key = value.toUpperCase();
    return LOCALE[key] ? LOCALE[key] : environmentConfig.LOCALE;
};

export const genBuddhistYear = () => {
    let buddhistYear = [];
    for (let year = MIN_BUDDHIST_YEAR; year <= MAX_BUDDHIST_YEAR; year++) {
        buddhistYear.push({ value: year });
    }
    return buddhistYear;
};