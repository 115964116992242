import QueryString from "query-string";
import { OS } from "src/constants/index";

export const randomCharacter = (length) => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    let result = '';
    for (let i = length; i > 0; --i) {
        result += characters[Math.floor(Math.random() * characters.length)];
    }
    return result;
};

export const randomString = (length) => {
    const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    let result = '';
    for (let i = length; i > 0; --i) {
        result += characters[Math.floor(Math.random() * characters.length)];
    }
    return result;
};

export const getParams = (urlParams) => {
    return QueryString.parse(urlParams);
};

export const naturalSorter = (as, bs) => {
    let a, b, a1, b1, i = 0, n, L,
        rx = /(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g;  /** Todo : แก้จาก regex ให้เป็น code ปกติ */
    if (as === bs) return 0;
    a = as.toLowerCase().match(rx);
    b = bs.toLowerCase().match(rx);
    L = a.length;
    while (i < L) {
        if (!b[i]) return 1;
        a1 = a[i];
        b1 = b[i++];
        if (a1 !== b1) {
            n = a1 - b1;
            if (!isNaN(n)) return n;
            return a1 > b1 ? 1 : -1;
        }
    }
    return b[i] ? -1 : 0;
};

export const messageToOpener = (message, target) => {
    if (window.opener && typeof window.opener.postMessage === 'function') {
        window.opener.postMessage(message, target);
    }
};

export const saveFile = (data, fileName) => {
    const blob = new Blob([data]);
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', fileName);
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
};

export const transformTrim = (text) => {
    if (text) {
        return text.trim();
    }
    return;
};

export const openWithNewTab = (url) => {
    window.open(url, "_blank");
};

export const isFacebookApp = () => {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
};

export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
        return OS.ANDROID;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        return OS.IOS;
    }
    return OS.DESKTOP;
};