import { apiInstance, authorizeInstance } from 'src/configs/axios.config';
import { AXIOS_SILENT_ALL, LINE_ID_EXIST, LINE_NO_FRIEND_STATUS, LINE_NOT_FRIEND_OR_BLOCKED } from "src/constants";

const MODULE = "authenticate";

export const getUser = (configs = {}) => apiInstance.get(`/v1/${MODULE}/profile`, configs);

export const getProfile = (configs = {}) => apiInstance.get(`panelist/v1/${MODULE}/profile`, configs);

export const updateProfile = (data, configs = {}) => apiInstance.put(`panelist/v1/${MODULE}/profile`, data, configs);

export const forgotPassword = (data, configs = {}) => authorizeInstance.post(`panelist/v1/${MODULE}/forgot_password`, data, configs);

export const register = (data, configs = {}) => authorizeInstance.post(`panelist/v1/${MODULE}/register`, data, configs);

export const resetPassword = (data, configs = {}) => apiInstance.post(`panelist/v1/${MODULE}/reset_password`, data, { ...configs, silent: [AXIOS_SILENT_ALL] });

export const validateForgotPassword = (data, configs = {}) => apiInstance.post(`panelist/v1/${MODULE}/validate/forget_password`, data, configs);

export const validateRegister = (data, configs = {}) => apiInstance.post(`panelist/v1/${MODULE}/validate/register`, data, configs);

export const verifyLine = (data, configs = {}) => apiInstance.post(`panelist/v1/${MODULE}/verify/line`, data, { ...configs, silent: [LINE_ID_EXIST, LINE_NO_FRIEND_STATUS, LINE_NOT_FRIEND_OR_BLOCKED] });

export const verifyEmail = (data, configs = {}) => apiInstance.post(`panelist/v1/${MODULE}/verify/email`, data, configs);

export const changePassword = (data, configs = {}) => apiInstance.put(`/v1/${MODULE}/password/change`, data, configs);

export const getUserPoint = (configs = {}) => apiInstance.get(`panelist/v1/${MODULE}/profile/point`, configs);