import React from 'react';
import styled from "styled-components";
// asset
import { ReactComponent as Logo } from "src/assets/logo/crowdster_logo.svg";
// Constant
import { SCREEN_SIZES } from "src/constants";

const WordLogo = () => {
    return (
        <StyledDiv className="word-logo">
            <Logo className="logo-image" />
        </StyledDiv>
    );
};
const StyledDiv = styled.div`
    &.word-logo {
            display:flex;
            align-items: center;
        .logo-image{
            width: clamp(100px, 15vw, 236px);
            height: auto;
        }
        @media only screen and (max-width: ${SCREEN_SIZES.XS}) {
            .logo-image {
                width: 132px;
                height: auto;
            }   
        }
    }
`;
export default WordLogo;