// APIs
import * as AuthenticateAPI from "src/apis/authenticate.api";
import * as AuthorizeAPI from "src/apis/authorize.api";

// Configs
import { apiInstance } from "src/configs/axios.config";

// Constants
import { AH_ACCESS_TOKEN, AXIOS_SILENT_ALL } from "src/constants";

// Helpers
import { getLocaleByKey } from "src/helpers/locale.helper";
import { setInstanceToken } from "src/helpers/axios.helper";

export const login = async ({ email, password, permanent }) => {
    const { payload, success: loginSuccess } = await AuthorizeAPI.login({ email, password, permanent }).catch(err => err);

    if (!loginSuccess) {
        return undefined;
    }

    const { token, tokenExpired } = payload;
    const headers = { [AH_ACCESS_TOKEN]: token };

    const { payload: userProfile, success: profileSuccess } = await AuthenticateAPI.getProfile({ headers }).catch(err => err);
    if (!profileSuccess) {
        return undefined;
    }

    setInstanceToken(apiInstance, token);

    return { userProfile, token, tokenExpired };
};

export const requestNewToken = async () => {
    const { payload, success } = await AuthorizeAPI.getNewToken().catch(err => err);

    if (!success) {
        return {};
    }

    const { token, tokenExpired } = payload;
    setInstanceToken(apiInstance, token);

    return { token, tokenExpired };
};

export const initialAuth = async () => {
    const { payload, success: getTokenSuccess } = await AuthorizeAPI.getNewToken({ silent: AXIOS_SILENT_ALL }).catch(err => err);

    if (!getTokenSuccess) {
        return undefined;
    }

    const { token, tokenExpired } = payload;
    const headers = { [AH_ACCESS_TOKEN]: token };

    const { payload: userProfile, success: profileSuccess } = await AuthenticateAPI.getProfile({ headers }).catch(err => err);
    if (!profileSuccess) {
        return undefined;
    }

    setInstanceToken(apiInstance, token);

    return { userProfile, token, tokenExpired, locale: getLocaleByKey(userProfile.locale), pdpaVersion: userProfile?.pdpaVersion };
};

export const requestResetPasswordMail = async (values) => {

    const response = await AuthenticateAPI.forgotPassword(values, { silent: [AXIOS_SILENT_ALL] }).catch(err => err);
    const { success } = response;

    if (success) {
        return { success };
    } else {
        return { success: false };
    }

};

export const validateResetPasswordToken = (token) => {
    return AuthenticateAPI.validateForgotPassword({ token }, { silent: [AXIOS_SILENT_ALL] });
};

export const validateEmailToken = (token) => {
    return AuthenticateAPI.validateRegister({ token }, { silent: [AXIOS_SILENT_ALL] });
};

export const register = async ({ params }) => {

    const data = {
        "email": params.email,
        "password": params.password,
        "pdpa_version": params.pdpaVersion,
        "permanent": false,
    };

    const response = await AuthenticateAPI.register(data, { silent: [AXIOS_SILENT_ALL] }).catch(err => err);

    const { success } = response;
    if (success) {

        const payload = await login({ ...data });

        return {
            success,
            payload
        };

    } else {

        return {
            success: false
        };
    }

};

export const resetPassword = async ({ params, resetToken }) => {
    const data = {
        "confirm_password": params.confirm,
        "password": params.password,
        token: resetToken
    };
    const { payload: { token, tokenExpired }, success: loginSuccess } = await AuthenticateAPI.resetPassword(data);

    if (!loginSuccess) {
        return undefined;
    }
    const headers = { [AH_ACCESS_TOKEN]: token };
    const { payload: userProfile, success: profileSuccess } = await AuthenticateAPI.getProfile({ headers }).catch(err => err);

    if (!profileSuccess) {
        return undefined;
    }

    setInstanceToken(apiInstance, token);
    return { userProfile, token, tokenExpired };
};

export const verifyEmail = () => {
    try {
        const { payload } = AuthenticateAPI.verifyEmail();
        return {
            success: true,
            payload
        };

    } catch (err) {
        return {
            success: false
        };
    }
};

export const changePassword = async ({ params }) => {
    try {
        const data = {
            "current_password": params.current,
            "new_password": params.password
        };
        const { payload } = await AuthenticateAPI.changePassword(data);
        return {
            success: true,
            payload
        };
    } catch (err) {
        return {
            success: false
        };
    }
};

export const getProfile = () => AuthenticateAPI.getProfile();

export const getUserPoint = async () => {

    const response = await AuthenticateAPI.getUserPoint().catch(err => err);

    const { payload, success } = response;

    if (success) {

        const { totalPoint } = payload;

        return totalPoint;
    } else {
        return 0;
    }

};
