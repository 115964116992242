import dayjs from "dayjs";

// Services
import * as Service from 'src/helpers/security.helper';

export const isAuthorize = (reqPermissions = [], userPermissions = [], { includeAll = false, blPermissions = [] } = {}) => {
    if (Service._isBlacklisted(blPermissions, userPermissions)) {
        return false;
    } else {
        return Service._isPermitted(reqPermissions, userPermissions, includeAll);
    }
};

export const _isBlacklisted = (blPermissions = [], userPermissions = []) => {
    if (blPermissions.length === 0) {
        return false;
    }

    const blPermObj = blPermissions.reduce((result, perm) => ({ ...result, [perm]: true }), {});
    return userPermissions.some(perm => blPermObj[perm]);
};

export const _isPermitted = (reqPermissions = [], userPermissions = [], includeAll = false) => {
    if (reqPermissions.length === 0) {
        return true;
    }

    const userPermObj = userPermissions.reduce((result, perm) => ({ ...result, [perm]: true }), {});
    if (includeAll) {
        return reqPermissions.every(perm => userPermObj[perm]);
    } else {
        return reqPermissions.some(perm => userPermObj[perm]);
    }
};

export const getTokenExpiredMs = (expiredDate) => dayjs(expiredDate).diff(dayjs());