import { createContext } from 'react';

export const AuthState = createContext({
    authState: false,
    userProfile: {},
    authorize: () => { },
    setLogout: () => { }
});
export const Locale = createContext({
    value: undefined,
    setLocale: () => { }
});