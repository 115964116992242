import React from 'react';
import { useIntl } from "react-intl";
import { Row, Col } from "antd";
import styled from "styled-components";
import themeConfig from "src/configs/theme.config";
// Helper
import { redirect } from "src/helpers/router.helper";
// Assets
import TitleImage from "src/assets/images/technical_error.svg";
// Components
import Button from "src/components/shared/button/CrowdButton";
// Constant
import { SCREEN_SIZES } from "src/constants";

const Error500 = () => {
    const intl = useIntl();

    const handleHome = () => {
        redirect("/home");
    };

    return (
        <StyledDiv className="error-500-page">
            <Row>
                <Col xs={2} sm={4}></Col>
                <Col xs={20} sm={16} className="main-col">
                    <div className="content">
                        <img className="page-image" src={TitleImage} alt="people fixing plugs" />
                        <div className="title"><span>{intl.formatMessage({ id: "technicalError.title" })}</span></div>
                        <div className="error-code">
                            <span>{intl.formatMessage({ id: "technicalError.code" })}</span>
                        </div>
                        <div className="subtitle">
                            <span>{intl.formatMessage({ id: "technicalError.subtitle" })}</span>
                            <span>{intl.formatMessage({ id: "technicalError.subtitle.2" })}</span>
                        </div>
                        <Button
                            className="close-btn"
                            size="normal"
                            onClick={handleHome}>{intl.formatMessage({ id: "technicalError.button.back" })}</Button>
                    </div>
                </Col>
            </Row>
        </StyledDiv>
    );
};
const StyledDiv = styled.div`
    &.error-500-page{
        text-align: center;
        padding: 80px 0;
        .content{
            text-align: center;
        }
        .email{
            color: ${themeConfig.color.primary_400}
        }
        .title{
            font-size: 1.75rem;
            font-weight: 500;
            color: ${themeConfig.color.primary_400};
            margin-top: 15px;
        }
        .error-code{
            margin: 28px 0 24px 0;
        }
        .subtitle{
            font-size: 1rem;
            font-weight: 300;
            display: grid;
            place-items: center;
            div{
                width: 65%;
            }
        }
        .close-btn{
            width: 244px;
            margin-top: 48px; 
        }
        div.ant-divider{
            margin: 32px 0;
        }
        @media only screen and (max-width: ${SCREEN_SIZES.XS}) {
            font-size: 0.875rem;
            padding: 50px 0;
            .subtitle{
                div{
                    width: 100%;
                }
            }
            .page-image{
                height: 196px;
                width: auto;
            }
            .title{
                font-size: 1.5rem;
                margin-top: 15px;
            }
        }
        @media only screen and (min-width: ${SCREEN_SIZES.XS}) {
            .main-col{
                display: grid;
                place-items: center;
            }
            &.content{
                width: min(500px, 90%) ;
            }
        }
    }
`;
export default Error500;