import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { useIntl } from "react-intl";
// Components
import ModalTemplate from "src/components/shared/modal/DialogTemplate";
import DialogFooter from "src/components/shared/modal/DialogFooter";
// Assets
import { ReactComponent as X } from "src/assets/images/man_holding_x.svg";

const propTypes = {
    onDiscard: PropTypes.func
};

const defaultProps = {
    onDiscard: () => { }
};

const AlertModal = ({ onDiscard, ...props }) => {
    const intl = useIntl();

    const footer = (
        <DialogFooter
            isSingle
            onRight={onDiscard}
            rightString={intl.formatMessage({ id: "application.ok" })}
        />
    );

    return (
        <StyledTemplateModal
            {...props}
            centered
            closable={false}
            image={<X />}
            footer={footer}
            titleString={intl.formatMessage({ id: "profile.edit.alertModal.title" })}
            className="ask-edit-modal"
        >
            <div className="content">
                <span className="subtitle-6">
                    {intl.formatMessage({ id: "profile.edit.alertModal.desc" })}
                </span>
            </div>
        </StyledTemplateModal>
    );
};

const StyledTemplateModal = styled(ModalTemplate)`
    &.ask-edit-modal {
        .children-wrapper {
            .content {
                padding: 0 36px;
            }
        }
        .dialog-footer {
            .footer-btn {
                button:first-child {
                    margin: 0;
                }
            }
        }
    }
`;

AlertModal.propTypes = propTypes;
AlertModal.defaultProps = defaultProps;

export default AlertModal;