const TYPES = {
    SET_CRITERIA: "SET_CRITERIA"
};

const defaultValue = {
    viewType: '',
    current: 1,
    pageSize: 12,
};

const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.SET_CRITERIA: {
            const { viewType, current, pageSize } = action.payload;
            return {
                ...state,
                viewType,
                current,
                pageSize
            };
        }
        default:
            return state;
    }
};

const rewardReducer = {
    TYPES,
    defaultValue,
    reducer
};

export default rewardReducer;