const TYPES = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    UPDATE_PROFILE: "UPDATE_PROFILE"
};

const defaultValue = {
    authState: false,
    userProfile: undefined
};
const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.LOGIN:
            return {
                authState: true,
                userProfile: action.payload
            };
        case TYPES.LOGOUT:
            return {
                authState: false,
                userProfile: undefined
            };
        case TYPES.UPDATE_PROFILE:
            return {
                authState: true,
                userProfile: action.payload
            };
        default:
            return state;
    }
};

const authenticateReducer = {
    TYPES,
    defaultValue,
    reducer
};

export default authenticateReducer;