/* eslint-disable */
import { LOCALE, PDPA_VERSION } from 'src/constants';

const {
    API_RESOURCE = process.env.REACT_APP_API_RESOURCE,
    API_SERVER = process.env.REACT_APP_API_SERVER,
    AUTHORIZE_SERVER = process.env.REACT_APP_AUTHORIZE_SERVER,
    LINE_CLIENT_ID = process.env.REACT_APP_LINE_CLIENT_ID,
    LINE_REDIRECT_URI = process.env.REACT_APP_LINE_REDIRECT_URI,
} = window.globalConfig || {};

export default {
    API_RESOURCE,
    API_SERVER,
    AUTHORIZE_SERVER,
    LINE_CLIENT_ID,
    LINE_REDIRECT_URI,
    VERSION: process.env.REACT_APP_VERSION,
    TIMEZONE: process.env.REACT_APP_DEFAULT_TIMEZONE,
    NOTIFICATION_DURATION: process.env.REACT_APP_NOTIFICATION_DURATION || 5,
    LOCALE: process.env.REACT_APP_LOCALE || LOCALE.TH,
    DEFAULT_TIMEZONE: process.env.REACT_APP_DEFAULT_TIMEZONE,
    DEFAULT_PAGE_AFTER_AUTH: "/home",
    PDPA_VERSION: PDPA_VERSION,
};