import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
// Components
import Button from "src/components/shared/button/CrowdButton.jsx";

import { SCREEN_SIZES } from "src/constants";
import { intl } from "src/configs/i18n.config";

const propTypes = {
    leftString: PropTypes.string,
    rightString: PropTypes.string,
    isSingle: PropTypes.bool,
    disabled: PropTypes.bool,
};

const defaultProps = {
    leftString: intl.formatMessage({ id: "application.cancel" }),
    rightString: intl.formatMessage({ id: "application.ok" }),
    isSingle: false,
    disabled: false,
};

const DialogFooter = ({ leftString, rightString, isSingle, onLeft, onRight, disabled }) => {
    const renderLeftButton = () => {
        if (isSingle === false) {
            return (
                <Button
                    size="normal"
                    type="light"
                    disabled={disabled}
                    onClick={onLeft}>
                    {leftString}
                </Button>);
        } else {
            return null;
        }
    };
    return (
        <StyledDiv className="dialog-footer">
            <div className="footer-btn">
                {renderLeftButton()}
                <Button
                    size="normal"
                    type="primary"
                    disabled={disabled}
                    onClick={onRight} >
                    {rightString}
                </Button>
            </div>
        </StyledDiv>
    );
};

DialogFooter.propTypes = propTypes;
DialogFooter.defaultProps = defaultProps;
const StyledDiv = styled.div`
    &.dialog-footer{
        text-align:center;
        .footer-btn{
            button {
                min-width: 135px;
                width: 155px;
                white-space: nowrap;
            }
            button:first-child{
                margin-right: 16px;
            }
        }
        @media only screen and (max-width: ${SCREEN_SIZES.XS}) {
            .footer-btn{
                button {
                    width: 40%;
                }
                button:first-child{
                    margin-right: 8px;
                }
            }
        }
        @media only screen and (max-width: 361px) {
            .footer-btn{
                button:first-child{
                    margin-right: 0;
                    margin-bottom: 5px;
                }
            }
        }
    }
`;
export default DialogFooter;