
const color = {
    white: "rgba(255, 255, 255, 1)",
    primary_200: "rgba(209, 223, 255, 1)",
    primary_300: "rgba(88, 134, 242, 1)",
    primary_400: "rgba(56, 104, 217, 1)",
    primary_500: "rgba(32, 69, 161, 1)",
    primary_600: "rgba(21, 48, 116, 1)",

    grey_200: "rgba(245, 245, 245, 1)",
    grey_300: "rgba(235, 235, 235, 1)",
    grey_400: "rgba(217, 217, 217, 1)",
    grey_500: "rgba(191, 191, 191, 1)",
    grey_600: "rgba(86, 86, 86, 1)",

    error: "rgba(255, 95, 95, 1)",
    success: "rgba(24, 195, 52, 1)",
    background: "rgba(249, 249, 249, 1)",

    brand: {
        line: "rgba(6, 199, 85, 1)"
    }
};

const breakpoint = 576;


const themeConfig = {
    color: color,
    functional: {
        error: color.error,
        success: color.success,
        background: color.background,
        breakpoint: breakpoint
    },
    nav: {
        color: color.primary_300,
        header: {
            height: {
                desktop: 64,
                mobile: 48
            }
        }
    },
    button: {
        primary: {
            default: {
                background: color.primary_500,
                border: color.primary_500,
                color: color.white,
            },
            hover: {
                background: color.primary_600,
                border: color.primary_600,
                color: color.white,
            },
            focus: {
                background: color.primary_600,
                border: color.primary_600,
                color: color.white,
            },
            disabled: {
                background: color.primary_200,
                border: color.primary_200,
                color: "rgba(88, 134, 242, 0.5)",
            }
        },
        light: {
            default: {
                background: color.white,
                color: color.primary_500,
                border: color.primary_500,
            },
            hover: {
                background: color.primary_200,
                color: color.primary_500,
                border: color.primary_500,
            },
            focus: {
                background: color.white,
                color: color.primary_500,
                border: color.primary_500,
            },
            disabled: {
                background: color.white,
                color: "rgba(32, 69, 161, 0.4)",
                border: "rgba(32, 69, 161, 0.4)",
            }
        },
    },
    font: {
        breakpoint: breakpoint,
        titleColor: "rgba(7, 3, 20, 0.88)",
        color: "rgba(28, 31, 41, 1)",
        lineHeight: 1.5,
        weight: {
            bold: 700,
            default: 400
        }
    },
    link: {
        normal: color.primary_500,
        hover: color.primary_500,
        hoverBg: "rgba(242, 244, 245, 1)",
    },
};

export default themeConfig;