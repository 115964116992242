import camelCase from 'lodash/camelCase';

// Configs
import { intl } from "src/configs/i18n.config";

// Constants
import { AH_ACCESS_TOKEN, AH_LOCALE, AXIOS_SILENT_ALL, REQUEST_ERRORS } from "src/constants";

export const isIgnore = (ignoreList = [], errorCode) => {
    if (ignoreList.length === 0) {
        return false;
    } else if (ignoreList.find(code => code === AXIOS_SILENT_ALL || code === errorCode) !== undefined) {
        return true;
    } else {
        return false;
    }
};

export const transformSuccessResponse = (response) => {
    const { data: { payload, needConfirm = false } } = response;

    return {
        payload,
        needConfirm,
        response,
        success: true
    };
};

export const transformErrorResponse = (errResponse, isCancelled) => {
    const { response, message } = errResponse;
    let error;

    if (message === REQUEST_ERRORS.NETWORK_ERROR) {
        error = { message };
    } else {
        error = response?.data.payload;
    }

    return {
        isCancelled,
        error,
        response: errResponse,
        success: false
    };
};

export const getErrorObj = ({ response, message: errMessage }) => {
    const { status, config, data = {} } = response;
    const { payload = {} } = data;
    let type, message, detail, internalDetail;

    if (errMessage === REQUEST_ERRORS.NETWORK_ERROR) {
        type = REQUEST_ERRORS.NETWORK_ERROR;
        message = intl.formatMessage({ id: 'application.error.networkError' });
    } else if (errMessage === REQUEST_ERRORS.SESSION_EXIPRED) {
        type = REQUEST_ERRORS.SESSION_EXIPRED;
        message = intl.formatMessage({ id: 'application.error.sessionExpired' });
    } else if (status === 404) {
        type = REQUEST_ERRORS.ENDPOINT_NOT_FOUND;
        message = intl.formatMessage({ id: 'application.error.urlNotFound' });
        detail = { url: config.url };
    } else if ([400, 403, 415, 422].indexOf(status) >= 0) {
        type = REQUEST_ERRORS.BUSINESS_EXCEPTION;
        message = payload.message;
    } else if (status === 401) {
        type = REQUEST_ERRORS.UNAUTHENTICATED_EXCEPTION;
        message = intl.formatMessage({ id: 'application.error.unauthenticated' });
    } else if (status === 503) {
        type = REQUEST_ERRORS.SERVICE_DOWN;
        message = intl.formatMessage({ id: 'application.error.serviceUnavailable' });
    } else {
        type = REQUEST_ERRORS.TECHNICAL_EXCEPTION;
        message = intl.formatMessage({ id: 'application.error.systemError' });
        detail = payload.detail;
        internalDetail = payload.internalMessage;
    }

    return {
        type,
        message,
        detail,
        internalDetail,
        response
    };
};

/** 
 * Parse object key to camel case
 * 
 * Objective: to work with Python because Python use snake case but Javascript prefer camel case If we don't parse it here
 * snake case will spread to every files in project and code smell will warning
*/
export const responseParser = (rawData) => {
    return JSON.parse(rawData, (name, value) => {
        if (value && typeof value === "object" && !Array.isArray(value)) {
            // It's a non-null, non-array object, create a replacement with the keys initially-capped
            const newValue = {};
            for (const key in value) {
                newValue[camelCase(key)] = value[key];
            }
            return newValue;
        }
        return value;
    });
};

export const setInstanceToken = (instance, token) => {
    instance.defaults.headers.common[AH_ACCESS_TOKEN] = token;
};

export const setInstanceLocale = (instance, locale) => {
    instance.defaults.headers.common[AH_LOCALE] = locale;
};