import React, { Suspense, Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
// Providers
import { SurveyProvider } from "src/contexts/survey.context";
import { RewardProvider } from "src/contexts/reward.context";
// Components
import ScrollToTop from "src/components/router/ScrollToTop";
import LoadingUnderHeader from "src/components/shared/loading/LoadingUnderHeader";
import PublicLayout from "src/components/layout/neutral/PublicLayout";
import ErrorBoundary from "src/components/shared/error/ErrorBoundary";
// Configs
import environmentConfig from "src/configs/environment.config";

const PageNotFound = React.lazy(() => import("src/containers/error/PageNotFound"));
const ErrorPage = React.lazy(() => import("src/containers/error/error500"));
const UnauthenticatedLayout = React.lazy(() => import("src/components/layout/unathenticated/UnauthenticatedLayout"));
const AuthenticatedLayout = React.lazy(() => import("src/components/layout/authenticate/AuthenticatedLayout"));
const PDPALayout = React.lazy(() => import("src/components/layout/pdpa/PDPALayout"));
const ForceUpdateProfileLayout = React.lazy(() => import("src/components/layout/profile/ForceUpdateProfileLayout"));
class Router extends Component {

    render() {
        const notFound = <Route component={PageNotFound} />;

        return (
            <ScrollToTop>
                <Suspense fallback={<LoadingUnderHeader headerHeight={64} />}>
                    <Switch>
                        <Route path={["/reward", "/redeem/done"]}>
                            <PublicLayout>
                                <ErrorBoundary>
                                    <Suspense fallback={<LoadingUnderHeader headerHeight={64} />}>
                                        <RewardProvider>
                                            <Switch>
                                                <Route exact path="/reward/list" component={React.lazy(() => import("src/containers/reward/list/RewardListPage.jsx"))} />
                                                <Route exact path="/redeem/done" component={React.lazy(() => import("src/containers/reward/detail/RewardRedeemSuccessPage.jsx"))} />
                                                {notFound}
                                            </Switch>
                                        </RewardProvider>
                                    </Suspense>
                                </ErrorBoundary>
                            </PublicLayout>
                        </Route>
                        <Route path={["/survey", "/profile", "/point", "/dashboard", "/unauthorized", "/home", "/account-info"]}>
                            <AuthenticatedLayout>
                                <PDPALayout>
                                    <ForceUpdateProfileLayout>
                                        <ErrorBoundary>
                                            <Suspense fallback={<LoadingUnderHeader headerHeight={64} />}>
                                                <Switch>
                                                    <Route exact path="/dashboard" component={React.lazy(() => import("src/containers/dashboard/DashboardPage"))} />
                                                    <Route exact path="/point" component={React.lazy(() => import("src/containers/point/PointPage.jsx"))} />
                                                    <Route path={["/profile"]}>
                                                        <Suspense fallback={<LoadingUnderHeader headerHeight={64} />}>
                                                            <Switch>
                                                                <Route exact path="/profile" component={React.lazy(() => import("src/containers/user/profile/ProfilePage"))} />
                                                                <Route exact path="/profile/account" component={React.lazy(() => import("src/containers/user/accountInfo/AccountInfoPage.jsx"))} />
                                                                <Route exact path="/profile/detail" component={React.lazy(() => import("src/containers/user/profile/EditProfilePage"))} />
                                                                <Route exact path="/profile/password" component={React.lazy(() => import("src/containers/user/profile/ChangePasswordPage"))} />
                                                                <Route exact path="/profile/change-password-done" component={React.lazy(() => import("src/containers/user/profile/ChangePasswordSuccessPage"))} />
                                                                <Route exact path="/profile/verify/email" component={React.lazy(() => import("src/containers/email/RequestVerifyEmail.jsx"))} />
                                                                <Route exact path="/profile/terms" component={React.lazy(() => import("src/containers/user/profile/TermsPage.jsx"))} />
                                                                <Route exact path="/profile/policy" component={React.lazy(() => import("src/containers/user/profile/PolicyPage.jsx"))} />
                                                                {notFound}
                                                            </Switch>
                                                        </Suspense>
                                                    </Route>
                                                    <Route exact path="/unauthorized" component={React.lazy(() => import("src/containers/error/Unauthorized"))} />
                                                    <Route exact path="/home" component={React.lazy(() => import("src/containers/home/HomePage.jsx"))} />

                                                    <Route path={["/survey"]}>
                                                        <Suspense fallback={<LoadingUnderHeader headerHeight={64} />}>
                                                            <SurveyProvider>
                                                                <Switch>
                                                                    <Route exact path="/survey/list" component={React.lazy(() => import("src/containers/survey/list/SurveyListPage.jsx"))} />
                                                                    <Route exact path="/survey/:id" component={React.lazy(() => import("src/containers/survey/detail/SurveyDetail.jsx"))} />
                                                                    {notFound}
                                                                </Switch>
                                                            </SurveyProvider>
                                                        </Suspense>
                                                    </Route>
                                                    {notFound}
                                                </Switch>
                                            </Suspense>
                                        </ErrorBoundary>
                                    </ForceUpdateProfileLayout>
                                </PDPALayout>
                            </AuthenticatedLayout>
                        </Route>
                        <Route path={["/confirm-email", "/verify-confirm-email", "/confirm-email-done", "/verify-email", "/invalid-email-link", "/email-done", "/verify/line", "/redeem"]}>
                            <AuthenticatedLayout>
                                <ErrorBoundary>
                                    <Suspense fallback={<LoadingUnderHeader headerHeight={64} />}>
                                        <Switch>
                                            <Route exact path="/confirm-email" component={React.lazy(() => import("src/containers/email/RequestVerifyRegister.jsx"))} />
                                            <Route exact path="/verify-confirm-email" component={React.lazy(() => import("src/containers/email/VerifyConfirmEmailLandingPage.jsx"))} />
                                            <Route exact path="/confirm-email-done" component={React.lazy(() => import("src/containers/email/ConfirmEmailSuccessPage"))} />
                                            <Route exact path="/verify-email" component={React.lazy(() => import("src/containers/email/VerifyEmailCheck.jsx"))} />
                                            <Route exact path="/invalid-email-link" component={React.lazy(() => import("src/containers/email/InvalidEmailConfirmLink.jsx"))} />
                                            <Route exact path="/email-done" component={React.lazy(() => import("src/containers/email/VerifyEmailSuccessPage.jsx"))} />
                                            <Route exact path="/verify/line" component={React.lazy(() => import("src/containers/user/profile/LandingVerifyLine"))} />
                                            <Route exact path="/redeem/:id" component={React.lazy(() => import("src/containers/reward/detail/RewardDetailPage.jsx"))} />
                                            {notFound}
                                        </Switch>
                                    </Suspense>
                                </ErrorBoundary>
                            </AuthenticatedLayout>
                        </Route>
                        <Route path={["/login", "/register"]}>
                            <ErrorBoundary>
                                <Suspense fallback={<LoadingUnderHeader headerHeight={64} />}>
                                    <Switch>
                                        <UnauthenticatedLayout>
                                            <Route exact path="/login" component={React.lazy(() => import("src/containers/login/LoginPage"))} />
                                            <Route exact path="/register" component={React.lazy(() => import("src/containers/register/RegisterPage"))} />
                                        </UnauthenticatedLayout>
                                        {notFound}
                                    </Switch>
                                </Suspense>
                            </ErrorBoundary>
                        </Route>
                        <Route path={["/confirm-line-info", "/invalid-line", "/request-reset", "/check-email", "/reset-password", "/reset-done", "/invalid-reset-link", "/error"]}>
                            <PublicLayout>
                                <ErrorBoundary>
                                    <Suspense fallback={<LoadingUnderHeader headerHeight={64} />}>
                                        <Switch>
                                            <Route exact path="/confirm-line-info" component={React.lazy(() => import("src/containers/line/WhyConfirmLine.jsx"))} />
                                            <Route exact path="/invalid-line" component={React.lazy(() => import("src/containers/error/InvalidLineAccount.jsx"))} />
                                            <Route exact path="/request-reset" component={React.lazy(() => import("src/containers/password/RequestResetPasswordPage.jsx"))} />
                                            <Route exact path="/check-email" component={React.lazy(() => import("src/containers/password/PleaseCheckEmailPage.jsx"))} />
                                            <Route exact path="/reset-password" component={React.lazy(() => import("src/containers/password/ResetPasswordPage.jsx"))} />
                                            <Route exact path="/reset-done" component={React.lazy(() => import("src/containers/password/ResetPasswordSuccessPage.jsx"))} />
                                            <Route exact path="/invalid-reset-link" component={React.lazy(() => import("src/containers/password/InvalidResetLinkPassword.jsx"))} />
                                            <Route exact path="/error" component={ErrorPage} />
                                            {notFound}
                                        </Switch>
                                    </Suspense>
                                </ErrorBoundary>
                            </PublicLayout>
                        </Route>
                        <Route exact path="/logout" component={React.lazy(() => import("src/containers/app/LogoutPage.jsx"))} />
                        <Redirect exact from="/" to={environmentConfig.DEFAULT_PAGE_AFTER_AUTH} />
                        <PublicLayout>
                            {notFound}
                        </PublicLayout>
                    </Switch>
                </Suspense>
            </ScrollToTop>
        );
    }
}

export default withRouter(Router);