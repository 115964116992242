export const PDPA_VERSION = '0';

export const LOCALE = {
    EN: { value: 'en', text: "English" },
    TH: { value: 'th', text: "ไทย" }
};

export const OS = {
    DESKTOP: "desktop",
    ANDROID: "android",
    IOS: "ios",
    UNKNOWN: "unknown"
};

// PATH
export const DEFAULT_SURVEY_PATH = "/survey/list?view=survey";
export const DEFAULT_REWARD_PATH = "/reward/list?view=reward";
// BRAND
export const BRAND_LINE_LINK = "https://lin.ee/S0zHXrY";
export const BRAND_MAIL = "contact@crowdster.me";
export const BRAND_LINE = "@CROWDSTER";

/* REGEX */
export const ANTD_EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/;
export const CURRENT_EMAIL_REGEX = /^[a-zA-Z0-9]+[a-zA-Z0-9-_]*(?:\.[a-zA-Z0-9-_]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9]+)*$/;
export const PASSWORD_WHITESPACE_REGEX = /^[^\s]+(\s+[^\s]+)*$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*_-])[0-9a-zA-Z!@#$%^&*_-]{8,}$/;
export const REGEX_VALID_URL = /^(http(s)?:\/\/)?(www.)?([a-zA-Z0-9])+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/[^\s]*)?$/;
export const DIGIT_REGEX = /^\d+$/;
export const EN_CHARACTER = /^[a-zA-Z -]+$/;
export const TH_CHARACTER = /^[\u0E01-\u0E5B]+$/;
export const TH_CHARACTER_AND_SPACE = /^[\u0E01-\u0E5B ]+$/;
export const PHONE_NO = /^\d{10}$/;
export const REWARD_REGEX = /^\d{10,13}$/;
export const REDEEM_BANK_REGEX = /^\d{10,19}$/;

// API HEADERS
export const AH_ACCESS_TOKEN = "token";
export const AH_LOCALE = "locale";

// LOCAL STORAGE
export const LS_LOCALE = "locale";

// SCREENS
export const SCREEN_SIZES = {
    XS: '576px'
};

// AXIOS
export const AXIOS_SILENT_ALL = "ALL";

export const REQUEST_ERRORS = {
    ENDPOINT_NOT_FOUND: "ENDPOINT_NOT_FOUND",
    BUSINESS_EXCEPTION: "BUSINESS_EXCEPTION",
    TECHNICAL_EXCEPTION: "TECHNICAL_EXCEPTION",
    UNAUTHENTICATED_EXCEPTION: "UNAUTHENTICATED_EXCEPTION",
    SERVICE_DOWN: "SERVICE_DOWN",
    SESSION_EXIPRED: "SESSION_EXIPRED",
    NETWORK_ERROR: "NETWORK_ERROR"
};

// ERROR CODES
export const SAME_PASSWORD_ERROR = "ACC001";
export const LINE_ID_EXIST = "LINE002";
export const LINE_NO_FRIEND_STATUS = "LINE004";
export const LINE_NOT_FRIEND_OR_BLOCKED = "LINE005";
export const SURVEY_JOB_NO_ACCESS_ERROR = "SUR007";
export const REW003_EMPTY_REWARD = "REW003";
// min max buddha year
export const MIN_BUDDHIST_YEAR = 2480;
export const MAX_BUDDHIST_YEAR = 2600;
// ENUM
export const THAILAND = "Thailand";

// SURVEY
export const SURVEY_TYPE = {
    ONLINE_SURVEY: "online_survey",
    OTHER_JOBS: "other_jobs"
};

export const SURVEY_JOB = {
    ONLINE: "online",
    OTHER: "other"
};

export const SURVEY_VIEW = {
    SURVEY: "survey",
    HISTORY: "history"
};

export const SURVEY_STATUS = {
    SUBMITTED: "submitted",
    NOT_SUBMITTED: "not_submitted",
    POINT_ISSUED: "point_issued"
};

// REWARD
export const REWARD_VIEW = {
    REWARD: "reward",
    HISTORY: "history"
};

export const REWARD_TYPE = {
    TRUE_MONEY: "true_money",
    BANK_ACCOUNT: "bank_account",
    PROMPT_PAY: "prompt_pay"
};

export const BANK_NAME = {
    KASIKORN: "kasikorn_bank"
};

// REDEMPTION
export const REDEEM_STATUS = {
    REDEEMING: "redeeming",
    ISSUED: "issued",
    REJECTED: "rejected"
};
export const REJECTED_REASON = {
    INVALID_INFORM: "invalid_inform",
    NUMBER_NOT_FOUND: "number_not_found"
};

// Point 
export const POINT_TYPE = {
    REDEEM: "redeem",
    REFUND: "refund",
    EARN: "earn",
    DEDUCT: "deduct"
};