// Helpers
import { getDefaultLocale } from "src/helpers/locale.helper";

const TYPES = {
    CHANGE_LOCALE: "CHANGE_LOCALE",
};

const defaultValue = getDefaultLocale();
const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.CHANGE_LOCALE:
            return action.payload;
        default:
            return state;
    }
};

const localeReducer = {
    TYPES,
    defaultValue,
    reducer
};

export default localeReducer;