import React, { useContext } from 'react';
import styled from "styled-components";
import { Layout } from "antd";
import { withRouter } from "react-router-dom";
import themeConfig from "src/configs/theme.config";
// Helper
import { getClassName } from 'src/helpers/component.helper';
// Component
import AuthenticatedHeader from "src/components/layout/authenticate/AuthenticatedHeader";
// Context
import { AuthState } from "src/contexts/security.context";

const PublicLayout = ({ children, className, location }) => {
    const { authState } = useContext(AuthState);
    return (
        <StyledLayout className={getClassName('default-layout', className)}>
            <AuthenticatedHeader authState={authState} location={location} />
            <Layout.Content className="site-content">
                {children}
            </Layout.Content>
        </StyledLayout>
    );
};

const StyledLayout = styled(Layout)`
    &.default-layout{
       height: 100%;
       min-height: 100vh;
       background: ${themeConfig.color.background};
       
       .site-content {
            min-height: 280px;
            background: ${themeConfig.color.background};
            transition: all .4s;
        }
    }
`;

export default withRouter(PublicLayout);