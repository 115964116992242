import React, { useState } from 'react';
import styled from "styled-components";
import { useIntl } from "react-intl";
import themeConfig from "src/configs/theme.config";
import { MenuOutlined, DownOutlined } from "@ant-design/icons";
import { Row, Dropdown } from "antd";
import dayjs from "dayjs";
// Assets
import { ReactComponent as WordLogo } from "src/assets/logo/mobile_crowdster_logo.svg";
// Helpers
import { redirect } from "src/helpers/router.helper";
// Component
import MobileWordLogo from "src/components/shared/logo/WordLogo";
import ContactDropdown from "src/components/layout/ContactDropdown";
import AlertModal from "src/components/profile/AlertModal";
//Asset
import { ReactComponent as PersonIcon } from "src/assets/images/icons/person.svg";
import { ReactComponent as GiftIcon } from "src/assets/images/icons/Gift.svg";
import { ReactComponent as PointIcon } from "src/assets/images/icons/Point.svg";
import { ReactComponent as SurveyIcon } from "src/assets/images/icons/Survey.svg";
import Sidebar from "src/components/layout/Sidebar";

import { DEFAULT_SURVEY_PATH, DEFAULT_REWARD_PATH } from "src/constants";

const navItems = [
    { id: "nav.header.survey", icon: <SurveyIcon />, path: DEFAULT_SURVEY_PATH },
    { id: "nav.header.point", icon: <PointIcon />, path: "/point" },
    { id: "nav.header.prize", icon: <GiftIcon />, path: DEFAULT_REWARD_PATH },
];

const AuthenticatedHeader = ({ authState, userProfile, location }) => {
    const module = location.pathname.split(["/"])[1];
    const [visible, setVisible] = useState(false);
    const [noticeModalVisible, setNoticeModalVisible] = useState(false);
    const intl = useIntl();

    const firstEdit = userProfile?.lastUpdatedDate ? false : true;
    const lastUpdatedDate = dayjs(userProfile?.lastUpdatedDate);
    const duration = dayjs().diff(lastUpdatedDate, "day");
    const forceUpdate = duration >= 180 ? true : false;

    const handleRedirect = (path) => {
        if (authState && userProfile !== undefined && firstEdit) {
            setNoticeModalVisible(true);
            return;
        } else if (authState && userProfile !== undefined && forceUpdate) {
            setNoticeModalVisible(true);
            return;
        } else if (authState) {
            redirect(path);
        } else if (path === "/reward/list?view=reward") {
            redirect(path);
        } else {
            redirect("/login", { open: true });
        }
    };

    const handleClick = (path) => {
        setVisible(false);
        handleRedirect(path);
    };

    const renderNavItems = () => (
        navItems.map(item => {
            const className = item.path.includes(module) ? "menu-item active" : "menu-item";
            return (
                <div className={className} key={`nav-item-${item.id}`} onClick={() => handleRedirect(item.path)}>
                    <span className="big-title">{intl.formatMessage({ id: item.id })}</span>
                </div>
            );
        }));

    const renderProfileIcon = () => {
        const className = module === 'profile' ? "icon-wrapper active" : "icon-wrapper";
        return (
            <div className={className} onClick={() => handleRedirect("/profile")}>
                <PersonIcon className="person-icon" />
            </div>
        );
    };

    return (
        <StyledDiv className="authenticated-header">
            <Row>
                <div className="desktop-view">
                    <div className="header-row">
                        <div className="logo-wrapper" onClick={() => handleRedirect("/home")}>
                            <WordLogo id="desktop-logo" />
                        </div>
                        <div className="nav-items-wrapper">
                            {renderNavItems()}
                            <Dropdown
                                trigger={['click']}
                                overlay={<ContactDropdown />}>
                                <div>
                                    <span className="big-title">
                                        {intl.formatMessage({ id: "nav.header.contact" })}
                                        <DownOutlined className="down-caret" />
                                    </span>
                                </div>
                            </Dropdown>
                            <div className="big-title divider">|</div>
                            {renderProfileIcon()}
                        </div>
                    </div>
                </div>
                <div className="mobile-view">
                    <div className="header-content">
                        <MenuOutlined className="menu-icon" onClick={() => setVisible(true)} />
                        <div className="logo-wrapper" onClick={() => handleRedirect("/home")} >
                            <MobileWordLogo className="mobile-logo" />
                        </div>
                        {renderProfileIcon()}
                    </div>
                </div>
            </Row>
            <Sidebar
                navItems={navItems}
                visible={visible}
                onClick={handleClick}
                setVisible={setVisible}
            />
            <AlertModal visible={noticeModalVisible} onDiscard={() => setNoticeModalVisible(false)} />
        </StyledDiv >
    );
};

const StyledDiv = styled.div`
    &.authenticated-header{
        z-index: 5;
        background-color: white;
        color: ${themeConfig.color.grey_600};
        .logo-wrapper{
            cursor: pointer;
            display:flex;
            align-items: center;
        }
        .menu-content{
            background: ${themeConfig.color.background}
        }
        .header-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            margin: 0 4%;
            .nav-items-wrapper{
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
        .side-nav-item{
            display: flex;
            align-items: center;
        }
        .menu-item{
            transition: border-color 0.3s, background 0.3s;
            height: 100%;
            display: flex;
            align-items: center;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            margin-right: 20px;
        }
        .menu-item.active{
            color: ${themeConfig.color.primary_400};
            border-bottom: 4px solid ${themeConfig.color.primary_400};
        }
        .menu-item:hover{
            color: ${themeConfig.color.primary_400};
            border-bottom: 4px solid ${themeConfig.color.primary_400};
        }
        .divider{
            margin: 0 10px 0 15px;
        }
        .down-caret{
            margin-left: 12px;
        }
        .big-title{
            font-weight: 400;
            font-size:  clamp(1rem, 2vw, 1.25rem);
            line-height: 2.775rem;
        }
        .mobile-view {
            height: ${themeConfig.nav.header.height.mobile}px;
            width: 100%;
            display: none;
        }
        .desktop-view {
            height: ${themeConfig.nav.header.height.desktop}px;
            display: flex;
            align-items: center;
            width: 100%;
        }

        .header-content{
            height:100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto 0;
            width: 95% ;
        }

        .icon-wrapper{
            cursor: pointer;
            border-radius: 50%;
            height: 36px;
            width: 36px;
            display: grid;
            place-content: center;
            transition: color 0.3s, background-color 0.3s;
        }
        .icon-wrapper:hover{
            background-color: ${themeConfig.color.primary_400};
            .person-icon{
                color: white;
            }
        }
        .icon-wrapper.active{
            background-color: ${themeConfig.color.primary_400};
            .person-icon{
                color: white;
            }
        }
        .person-icon{
            cursor: pointer;
            height: 20px;
            width: 20px;
            color: ${themeConfig.color.grey_600};
        }
        .menu-icon{
           font-size: 28px;
        }

        div > div > span {
            cursor: pointer;
        }

        @media only screen and (max-width: 675px) {
            .mobile-logo{
                height: 16px;
                width: auto;
            }
            .desktop-view{
                display: none;
            }
            .mobile-view{
                display: block;
            }
            .icon-wrapper{
                height: 34px;
                width: 34px;
            }
            .person-icon{
                height: 16px;
                width: 16px;
            }
            .menu-icon{
                font-size: 24px;
            }
        }
    }
`;
export default AuthenticatedHeader;