// Config
import router from 'src/configs/router.config';

export const redirect = (targetPath, state = {}) => {
    return router.push(targetPath, state);
};

export const pushQuery = (url) => {
    window.history.pushState({}, "", url);
};

export const backToPrev = () => {
    return router.goBack();
};