import React from 'react';
import styled from "styled-components";
import { Menu } from 'antd';
import PropTypes from 'prop-types';
// Assets
import { ReactComponent as MailIcon } from "src/assets/images/icons/email_colored.svg";
import { ReactComponent as LineIcon } from "src/assets/images/icons/line_logo_2.svg";
import themeConfig from "src/configs/theme.config";
// Constants
import { BRAND_LINE_LINK, BRAND_LINE, BRAND_MAIL } from "src/constants";
// Helpers
import { getClassName } from "src/helpers/component.helper";

const propTypes = {
    sidebar: PropTypes.bool
};

const defaultProps = {
    sidebar: false
};

const ContactDropdown = ({ sidebar, className }) => {
    const menuClass = sidebar ? 'sidebar' : '';
    const handleLine = () => {
        window.open(BRAND_LINE_LINK, "_blank");
    };

    const handleMail = () => {
        window.open(`mailto:${BRAND_MAIL}`);
    };

    return (
        <StyledMenu className={getClassName("contact-dropdown", className)}>
            <Menu.Item key="line">
                <div className={getClassName("menu-item", menuClass)} onClick={handleLine}>
                    <LineIcon className="line-icon" />
                    <span>{BRAND_LINE}</span>
                </div>
            </Menu.Item>
            <Menu.Item key="mail">
                <div className={getClassName("menu-item", menuClass)} onClick={handleMail}>
                    <MailIcon />
                    <span>{BRAND_MAIL}</span>
                </div>
            </Menu.Item>
        </StyledMenu>
    );
};

const StyledMenu = styled(Menu)`
    &.contact-dropdown{
        border-right: none;
        border-radius: 10px;
        .menu-item{
            font-size: 1rem;
            color: ${themeConfig.color.primary_300};
            display: flex;
            align-items: center;
            span{
                margin-left: 10px;
            }
        }
        .sidebar{
            font-size: 0.875rem !important;
        }
        .line-icon{
            margin-left: 2px;
        }
    }
`;
ContactDropdown.propTypes = propTypes;
ContactDropdown.defaultProps = defaultProps;
export default ContactDropdown;