import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { intl } from "src/configs/i18n.config";
import { Modal } from "antd";
// Helper
import { getClassName } from "src/helpers/component.helper";
// Assets
import RedBin from "src/assets/images/icons/red_bin.svg";
import themeConfig from "src/configs/theme.config";

import { SCREEN_SIZES } from "src/constants";

const propTypes = {
    titleString: PropTypes.string,
    tooltip: PropTypes.object,
    image: PropTypes.object,
    footer: PropTypes.object,
    onClose: PropTypes.func
};

const defaultProps = {
    titleString: intl.formatMessage({ id: "accountInfo.delete" }),
    tooltip: null,
    image: <img src={RedBin} alt="trash bin" />,
    footer: null,
    onClose: () => { }
};

const DialogTemplate = ({ className, titleString, tooltip, image, footer, onClose, children, ...props }) => {

    const contentClassName = image === null ? "content no-image" : "content";
    return (
        <StyledModal
            {...props}
            onCancel={onClose}
            footer={footer}
            destroyOnClose={true}
            className={getClassName("dialog-template", className)}>
            <div className={contentClassName}>
                <div className="image-wrapper">
                    {image}
                </div>
                <div className="dialog-title">
                    <span>{titleString}</span>
                    {tooltip}
                </div>
                <div className="children-wrapper">
                    {children}
                </div>
            </div>
        </StyledModal>
    );
};

DialogTemplate.propTypes = propTypes;
DialogTemplate.defaultProps = defaultProps;
const StyledModal = styled(Modal)`
    &.dialog-template{
        text-align: center;
        .content{
            padding: 0 45px 24px 45px;
        }
        .content.no-image{
            padding: 25px 45px;
        }
        .image-wrapper{
            img{
                width: 150px;
                height: auto;
            }
            svg{
                width: 150px;
                height: auto;
            }
        }
        .dialog-title{
            display: flex;
            align-items: center;
            justify-content: center;

            font-weight: 400;
            font-size: 1.25rem;
            color: ${themeConfig.color.primary_400};
            margin-bottom: 12px;
            svg{
                margin-left: 4px;
            }
        }
        .ant-modal-content {
            border-radius: 30px;
            margin: auto;
            width: 405px;
        }
        .ant-modal-footer{
            border: none;
            padding-bottom: 32px;
        }
        @media only screen and (max-width: ${SCREEN_SIZES.XS}) {
            .ant-modal-footer{
                padding-bottom: 22px;
            }
            .dialog-title{
                font-size: 1.125rem;
            }
            .ant-modal-content {
                width: 90%;
                border-radius: 20px;
            }
            .content{
                padding: 0 30px 24px 30px;
            }
            .children-wrapper{
                font-size: 0.875rem;
            }
        }
    }
`;
export default DialogTemplate;