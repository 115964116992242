import React from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';
// Components
import { Col, Row, Spin } from "antd";

const propTypes = {
    headerHeight: PropTypes.number
};
const defaultProps = {
    headerHeight: 0
};

const LoadingUnderHeader = ({ headerHeight }) => {
    return (
        <StyledRow
            style={{ height: `calc(100vh - ${headerHeight}px)` }}
            justify="space-around" align="middle">
            <Col span={24}>
                <Spin size="large" />
            </Col>
        </StyledRow>
    );
};
const StyledRow = styled(Row)`
    & {
        text-align: center;
    }
`;
LoadingUnderHeader.propTypes = propTypes;
LoadingUnderHeader.defaultProps = defaultProps;
export default LoadingUnderHeader;