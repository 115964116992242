import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ErrorPage from "src/containers/error/error500";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
        const { history } = this.props;
        history.listen((location, action) => {
            if (this.state.hasError) {
                this.setState({
                    hasError: false,
                });
            }
        });
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service.Example send error to sentry
        console.error({ error });
        console.error(errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (<ErrorPage />);
        }
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);