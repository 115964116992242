import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

// Configs
import themeConfig from "src/configs/theme.config";

// Helpers
import { getClassName } from "src/helpers/component.helper";

const propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    icon: PropTypes.element,
    size: PropTypes.oneOf(["large", "normal", "medium", "small"]),
    htmlType: PropTypes.string,
    type: PropTypes.oneOf(["primary", "light"]),
    onClick: PropTypes.func
};

const defaultProps = {
    size: "medium",
    type: "primary",
    disabled: false,
    loading: false,
    icon: null,
    onClick: () => { }
};


const getSizeClass = (size) => {
    switch (size) {
        case "large":
            return "crowd-btn--lg";
        case "normal":
            return "crowd-btn--normal";
        case "medium":
            return "crowd-btn--md";
        case "small":
            return "crowd-btn--sm";
        default:
            return undefined;
    }
};

const getIconClass = (children, icon) => {
    if (children && icon) {
        return "crowd-btn--with-icon";
    } else if (icon) {
        return "crowd-btn--icon-only";
    } else {
        return undefined;
    }
};

const getTypeClass = (type) => {
    return type ? `crowd-btn--${type}` : undefined;
};

const CrowdButton = ({ children, className, disabled, loading, icon, size, shape, htmlType, type, block, onClick, ...props }) => {
    const childComponent = children ? <span>{children}</span> : null;
    const iconComponent = loading ? <LoadingOutlined spin /> : icon;

    const loadingClass = loading ? "crowd-btn--loading" : undefined;
    const blockClass = block ? "crowd-btn--block" : undefined;
    const handleClick = (e) => {
        if (loading) {
            e.preventDefault();
            return;
        }
        onClick();
    };
    return (
        <StyledButton
            className={getClassName(
                "crowd-btn",
                type,
                getSizeClass(size),
                getTypeClass(type),
                getIconClass(children, icon),
                loadingClass,
                blockClass,
                className
            )}
            type={htmlType}
            disabled={disabled}
            onClick={handleClick}
            {...props}
        >
            {childComponent}
            {iconComponent}
        </StyledButton>
    );
};

const StyledButton = styled.button`
        &.crowd-btn {
            outline: none;
            font-style: normal;
            border-radius: 100px;
            text-align: center;
            cursor: pointer;
            transition: background-color .3s;
            min-width: 40px;
            padding: 0.6em 1em;
            line-height: 1.375em;
            font-weight: 300;

            &.crowd-btn-loading {
                cursor: not-allowed;
                border-style: solid;
    
                span:first-child {
                    padding-right: 9.6px;
                }
    
                &.crowd-btn-icon-only {
                    span:first-child {
                        padding-right: unset;
                    }
                }
            }
        }
        
        &.crowd-btn--primary{
            background-color: ${themeConfig.button.primary.default.background};;
            border: 1px solid ${themeConfig.button.primary.default.border};
            color: ${themeConfig.button.primary.default.color};

            &.crowd-btn--loading {
                background-color: ${themeConfig.button.primary.disabled.background};;
                border: 1px solid ${themeConfig.button.primary.disabled.border};
                color: ${themeConfig.button.primary.disabled.color};

                 &:hover, &:active {
                    background-color: ${themeConfig.button.primary.disabled.background};;
                    border: 1px solid ${themeConfig.button.primary.disabled.border};
                    color: ${themeConfig.button.primary.disabled.color};
                }
            }

            &:hover{
                background-color: ${themeConfig.button.primary.hover.background};;
                border: 1px solid ${themeConfig.button.primary.hover.border};
                color: ${themeConfig.button.primary.hover.color};
            }
            &:focus, :active{
                background-color: ${themeConfig.button.primary.focus.background};;
                border: 1px solid ${themeConfig.button.primary.focus.border};
                color: ${themeConfig.button.primary.focus.color};
            }
            &:disabled{
                background-color: ${themeConfig.button.primary.disabled.background};;
                border: 1px solid ${themeConfig.button.primary.disabled.border};
                color: ${themeConfig.button.primary.disabled.color};
                cursor: not-allowed;
            }
        }

        &.crowd-btn--light{
            background-color: ${themeConfig.button.light.default.background};;
            border: 1px solid ${themeConfig.button.light.default.border};
            color: ${themeConfig.button.light.default.color};

            &.crowd-btn--loading {
                background-color: ${themeConfig.button.light.disabled.background};;
                border: 1px solid ${themeConfig.button.light.disabled.border};
                color: ${themeConfig.button.light.disabled.color};
                
                 &:hover, &:active {
                    background-color: ${themeConfig.button.light.disabled.background};;
                    border: 1px solid ${themeConfig.button.light.disabled.border};
                    color: ${themeConfig.button.light.disabled.color};
                }
            }
            
            &:hover{
                background-color: ${themeConfig.button.light.hover.background};;
                border: 1px solid ${themeConfig.button.light.hover.border};
                color: ${themeConfig.button.light.hover.color};
            }
            &:focus, :active{
                background-color: ${themeConfig.button.light.focus.background};;
                border: 1px solid ${themeConfig.button.light.focus.border};
                color: ${themeConfig.button.light.focus.color};
            }
            &:disabled{
                background-color: ${themeConfig.button.light.disabled.background};;
                border: 1px solid ${themeConfig.button.light.disabled.border};
                color: ${themeConfig.button.light.disabled.color};
                cursor: not-allowed;
            }
        }

        &.crowd-btn--sm {
                font-size: 1rem;
            }

            &.crowd-btn--normal {
                font-size: 1.125rem;
            }
    
            &.crowd-btn--md {
                font-size: 1.25rem;
            }
    
            &.crowd-btn--lg {
                font-size: 1.8rem;
            }


`;
CrowdButton.propTypes = propTypes;
CrowdButton.defaultProps = defaultProps;

export default CrowdButton;