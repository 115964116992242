import { message as antMessage } from 'antd';

// Helpers
import { randomString } from "src/helpers/common.helper";
import * as NotificationHelper from "src/helpers/notification.helper";

let notiList = [];

export const notification = ({ type, message, options }) => {
    if (NotificationHelper.isDuplicate(notiList, { type, message, options })) {
        return;
    }

    const notiObj = { key: randomString(10), type, message };

    notiList.push(notiObj);
    antMessage[type]({
        ...options,
        content: message,
        onClose: () => NotificationHelper.close(notiObj)
    });
};

export const destroy = () => {
    antMessage.destroy();
    notiList = [];
};

export const close = ({ key }) => {
    notiList = notiList.filter(item => item.key !== key);
};

export const isDuplicate = (list, notiObj) => {
    if (list.length === 0) {
        return false;
    }

    return list.find(item => {
        return item.type === notiObj.type && item.message === notiObj.message && item.detail === notiObj.detail;
    }) !== undefined;
};