import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import WebFont from 'webfontloader';

// Configs
import history from 'src/configs/router.config';
import Config from 'src/configs/environment.config';
import 'src/configs/accounting.config';
import 'src/configs/axios.config';
import 'src/configs/dayjs.config';

// Components
import AppPage from "src/containers/app/AppPage";
import Authenticate from "src/containers/app/Authenticate";

const App = () => {
    useEffect(() => {
        const loadFonts = () => {
            WebFont.load({
                google: {
                    families: ['Kanit', 'Poppins']
                }
            });
        };
        loadFonts();
        console.log(`App Version: ${Config.VERSION}`);
    }, []);

    return (
        <Router history={history}>
            <Authenticate>
                <AppPage />
            </Authenticate>
        </Router>
    );
};

export default App;